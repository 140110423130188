import { useCallback } from 'react';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { verifiVariable } from '../../../../utils/enums';

const useExportToExcel = (data, fileName) => {

    const exportToExcel = useCallback(() => {

        // Verifica que existan datos, de no existir detiene el proceso
        if (!verifiVariable(data) && data.length === 0) return;
        // Crea una hoja de cálculo a partir de los datos
        const worksheet = XLSX.utils.json_to_sheet(data);
        // Crea un libro de trabajo y agrega la hoja de cálculo
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        // Convierte el libro de trabajo a un archivo de Excel
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // Crea un blob a partir del buffer de Excel
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        // Utiliza FileSaver para descargar el archivo
        FileSaver.saveAs(dataBlob, `${fileName}.xlsx`);
    }, [data, fileName]);

    return exportToExcel;
};

export default useExportToExcel;