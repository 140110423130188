import { createRef, useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from '@mui/icons-material/Close';

import { ExpressProcessStyles } from "../../index.css";
import { verifiVariable } from "../../../../../utils/enums";

const DraggableBox = ({
    viewport,
    positions,
    signatory,
    deleteBox,
    refCanvas,
}) => {
    // eslint-disable-next-line
    const [width, setWidth] = useState("auto");
    // eslint-disable-next-line
    const [height, setHeight] = useState("auto");
    const styles = ExpressProcessStyles({
        width,
        height,
        layerX: signatory["positionBoxX"],
        layerY: signatory["positionBoxY"],
        iconColor: "red",
    });
    const [textBox, setTextBox] = useState("");
    const boxRef = createRef();

    const verifiDragAndDrop = (canvas, event) => {

        const rect = canvas.getBoundingClientRect();

        const offsetX = (event.clientX - rect.left);
        const offsetY = (event.clientY - rect.top);

        const scaledX = offsetX * (canvas.width / rect.width);
        const scaledY = offsetY * (canvas.height / rect.height);

        const boxIsInsideCanvas = scaledX >= 0 && scaledX <= canvas.width && scaledY >= 0 && scaledY <= canvas.height;

        return boxIsInsideCanvas;
    }


    useEffect(() => {
        let newText = "";

        if (!verifiVariable(signatory)) {
            const splitText = signatory["textTootip"].split(" ");

            const countWords = splitText.length;

            if (countWords === 1) {
                setTextBox(splitText[0].substr(0, 8));
            } else {
                splitText.forEach((texto, index) => {
                    if (index === 0) {
                        newText += texto.length > 8 ? texto.substr(0, 8) : texto;
                    } else {
                        newText += `${index > 1 ? "" : " "}${texto
                            .substring(0, 1)
                            .toUpperCase()}`;
                    }
                });

                setTextBox(newText);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatory]);

    useEffect(() => {

        (async () => {

            const element = window["jQuery"](boxRef.current);

            let startOffsetX = 0;
            let startOffsetY = 0;
            let startElementLeft = 0;
            let startElementTop = 0;

            element.draggable({
                start: (event) => {
                    startOffsetX = event.originalEvent.offsetX;
                    startOffsetY = event.originalEvent.offsetY;
                    startElementLeft = element.position().left;
                    startElementTop = element.position().top;
                },
                stop: (event) => {
                    const canvas = refCanvas.current;
                    const rect = canvas.getBoundingClientRect();

                    const scaleX = canvas.width / rect.width;
                    const scaleY = canvas.height / rect.height;
                    const offsetX = event.clientX - rect.left;
                    const offsetY = event.clientY - rect.top;

                    const startX = parseFloat(startOffsetX);
                    const startY = parseFloat(startOffsetY);
                    const deltaX = offsetX - startX;
                    const deltaY = offsetY - startY;


                    const result = verifiDragAndDrop(canvas, event);

                    if (!result) {

                        element.css({
                            left: startElementLeft,
                            top: startElementTop
                        });

                        return;
                    }

                    const CoordenadaX = (deltaX * scaleX - 2) / viewport.scale;
                    const CoordenadaY = (deltaY * scaleY + 20) / viewport.scale;

                    positions(
                        Object.assign(
                            { ...signatory },
                            {
                                x: CoordenadaX,
                                y: CoordenadaY,
                                positionBoxX: element.position().left,
                                positionBoxY: element.position().top,
                            }
                        )
                    );
                },
            });
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tooltip
            title={`Firmante ${signatory["numbSigned"]}`}
            arrow
            placement="bottom-start"
        >
            <div
                className={styles.dragableBox}
                ref={boxRef}
                style={{
                    height: `${height}`,
                    width: `${width}`
                }}
            >

                <Typography variant="h6" color={[signatory["textColor"]]}>
                    {textBox}
                    <CloseIcon
                        className={styles.textIcon}
                        onClick={() => deleteBox()}
                    />
                </Typography>

            </div>
        </Tooltip>
    );
};

export default DraggableBox;
