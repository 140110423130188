import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import ContractListHeader from "../ContractListHeader";
import { getContractsCreated } from "../../../store/contract/contractDetail";

import Spinner from "../../spinners/Spinner";
import GeneralListProced from "../general-list-proced";
import CustomToobarTableList from "../../customToobarTableList";
import { setearFecha, to } from "../../../utils/enums";

const RevisionManagement = () => {
    const {
        session: {
            login: { user },
        },
        contract: {
            contractCreator: { loading, contractListActive },
        },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dataGrid, setDataGrid] = useState([]);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        const { userId, token, profileId } = user;

        if (![null, undefined].includes(userId)) {
            dispatch(getContractsCreated(userId, token, profileId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const { status, message } = contractListActive;

        if (["success"].includes(status)) {
            setDataGrid(message);
        }
    }, [contractListActive]);

    const updateData = () => {
        const { userId, token, profileId } = user;
        setSpinner(true);
        if (![null, undefined].includes(userId)) {
            dispatch(getContractsCreated(userId, token, profileId));
        }

        setTimeout(() => {
            setSpinner(false);
        }, 5000);
    };

    const renderList = useCallback(() => {
        const arrayList = [...dataGrid].filter(
            (item) =>
                ["CREATED", "REVIEW", "REJECTED"].includes(item["sStatus"]) &&
                item["sProcess"] === "NORMAL"
        );

        return (
            <Fragment>
                <br />

                <CustomToobarTableList
                    dataGrid={[...arrayList].map((item) => {
                        return {
                            sProcess: item["sProcess"],
                            sContractID: item["sContractID"],
                            autoId: item["autoId"],
                            sContractName: item["sContractName"],
                            sTipoContrato: item["sTipoContrato"],
                            sTipoFirma: item["sTipoFirma"],
                            sStatus: item["sStatus"],
                            sPaymentStatus: item["sPaymentStatus"],
                            dContractDT: setearFecha(item["dContractDT"]),
                            withDocument: item["withDocument"],
                            iSignedCount: item["iSignedCount"],
                        };
                    })}
                    title={"Trámites pendientes de revisión."}
                    subTitle={
                        "Aquí podrás visualizar todos los trámites que tienes creados."
                    }
                    onUpdate={() => updateData()}
                    onCreate={() => navigate(to.CONTRACT_CREATION)}
                />

                <div
                    className="container--relative contracts-status-list__container"
                    style={{ height: "auto", width: "100%" }}
                >
                    {spinner || loading ? (
                        <Box
                            sx={{ height: "150px" }}
                            className="container--relative contracts-status-list__container"
                        >
                            <Spinner loading={true} type="bar" opacity={true} />
                        </Box>
                    ) : (
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <GeneralListProced
                                    updateList={() => updateData()}
                                    dataGrid={[...arrayList].map((item) => {
                                        return {
                                            sProcess: item["sProcess"],
                                            sContractID: item["sContractID"],
                                            autoId: item["autoId"],
                                            sContractName: item["sContractName"],
                                            sTipoContrato: item["sTipoContrato"],
                                            sTipoFirma: item["sTipoFirma"],
                                            sStatus: item["sStatus"],
                                            sPaymentStatus: item["sPaymentStatus"],
                                            dContractDT: setearFecha(item["dContractDT"]),
                                            withDocument: item["withDocument"],
                                            iSignedCount: item["iSignedCount"],
                                        };
                                    })}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Fragment>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, spinner, loading]);

    return (
        <div className="content-detail__container">
            <ContractListHeader />
            {renderList()}
        </div>
    );
};

export default RevisionManagement;
