import { useState, useEffect, useCallback, Fragment } from "react";
import { gridColumns } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import {
    getTramitsStorage,
    getTypeTramitStorage,
} from "../../../store/contract/tramites";
import Spinner from "../../spinners/Spinner";
import { ContractListHeader, CustomToobarTableList, GeneralListProced } from "../..";
import { getUsersStorage } from "../../../store/users/userList";
import { setearFecha, typeUser, verifiVariable } from "../../../utils/enums";
import ModalAlert from "../../floats/ModalAlert";
import { getContractsCreated } from "../../../store/contract/contractDetail";
import { getAllAnfTramit } from "../../../store/formANFTramit";

const ManageProcedures = () => {
    const {
        session: {
            login: { user },
        },
        users: {
            listUsers,
            loading,
            status: requestStatusUsersList,
            message: resquestUserListMsg,
        },
        formANFTramit: { allAnfTramit, loading: loading2 },
        contract: {
            contractCreator: { contractListActive, loading: loading1 },
            tramites: {
                tramList,
                typeTramList,
                loadingTramits,
                loadingTypeTramits,
                status,
                message,
            },
        },
    } = useSelector((state) => state.root);

    const dispatch = useDispatch();

    const [textSpinner, setTextSpinner] = useState("");
    const [dataGrid, setDataGrid] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState({});

    const getContracts = () => {
        const { token, profileId, userId } = user;

        if (![null, undefined].includes(userId)) {
            dispatch(getAllAnfTramit(token));
        }

        if (profileId === typeUser.ADMIN) {
            dispatch(getUsersStorage(token));

            dispatch(getTramitsStorage(token));

            dispatch(getTypeTramitStorage(token));
        } else {
            dispatch(getContractsCreated(userId, token, profileId));
        }

    };

    useEffect(() => {
        if (![null, undefined, "success", ""].includes(status)) {

            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: message,
                buttonText: "Cerrar",
                modalIcon: "warnIcon"
            });
            setShowModal(true);
        } else if (
            ![null, undefined, "success", ""].includes(requestStatusUsersList)
        ) {
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: resquestUserListMsg,
                buttonText: "Cerrar",
                modalIcon: "warnIcon"
            });
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, requestStatusUsersList]);

    useEffect(() => {
        const { status, message } = contractListActive;
        let tramites = [];
        let anfArrayList = [];
        setTextSpinner("Cargando listado de trámites...");

        const { profileId } = user;

        if (Array.isArray(allAnfTramit)) {

            anfArrayList = [...allAnfTramit].filter((item, index) => !verifiVariable(item["numberID"]))
                .map((item) => {
                    return {
                        sProcess: "ANF",
                        sContractID: item["contractID"],
                        autoId: item["numberID"],
                        sContractName: item["contractName"],
                        sTipoContrato: item["typeContract"],
                        sTipoFirma: "ANF",
                        sStatus: item["status"],
                        sPaymentStatus: item["paymentStatus"],
                        dContractDT: item["createdAt"],
                        withDocument: null,
                        iSignedCount: item["signedCount"],
                    };
                });

        }

        if (profileId === typeUser.ADMIN) {

            if (Array.isArray(tramList)) {

                tramites = tramList.map((tramite) => {
                    listUsers.forEach((user) => {
                        if (tramite.iContractOwner === user.iUserID) {
                            tramite = { ...tramite, user };
                        }
                    });

                    typeTramList.forEach((type) => {
                        if (tramite.iContractTypeFeeID === type.iContractTypeID) {
                            tramite = {
                                ...tramite,
                                type,
                            };
                        }
                    });

                    return tramite;
                });
            }

        }

        if (profileId !== typeUser.ADMIN && ["success"].includes(status)) {
            tramites = message.filter((item) => item["withDocument"] === 1);
        }

        setDataGrid([...tramites, ...anfArrayList]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tramList, listUsers, typeTramList, contractListActive, allAnfTramit]);

    useEffect(() => {
        getContracts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };

    const renderList = useCallback(() => {
        return (
            <Fragment>
                <br />

                <CustomToobarTableList
                    dataGrid={[...dataGrid].map((item) => {
                        return {
                            sProcess: item["sProcess"],
                            sContractID: item["sContractID"],
                            autoId: item["autoId"],
                            sContractName: item["sContractName"],
                            sTipoContrato: item["sTipoContrato"],
                            sTipoFirma: item["sTipoFirma"],
                            sStatus: item["sStatus"],
                            sPaymentStatus: item["sPaymentStatus"],
                            dContractDT: setearFecha(
                                item["dContractDT"]
                            ),
                            withDocument: item["withDocument"],
                            iSignedCount: item["iSignedCount"],
                        };
                    })}
                    title={"Historial de trámites."}
                    subTitle={"Resumen de historial de trámites."}
                    onUpdate={() => {
                        setTextSpinner("Actualizando listado de trámites...");
                        getContracts();
                    }}
                    onCreate={() => ""}
                    onDisableCreate={true}
                />

                <div
                    className="container--relative contracts-status-list__container"
                    style={{ height: "auto", width: "100%" }}
                >
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <GeneralListProced
                                dataGrid={[...dataGrid].map((item) => {
                                    return {
                                        sProcess: item["sProcess"],
                                        sContractID: item["sContractID"],
                                        autoId: item["autoId"],
                                        sContractName: item["sContractName"],
                                        sTipoContrato: item["sTipoContrato"],
                                        sTipoFirma: item["sTipoFirma"],
                                        sStatus: item["sStatus"],
                                        sPaymentStatus: item["sPaymentStatus"],
                                        dContractDT: setearFecha(
                                            item["dContractDT"]
                                        ),
                                        withDocument: item["withDocument"],
                                        iSignedCount: item["iSignedCount"],
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, loadingTramits, loadingTypeTramits, gridColumns]);

    return (
        <div className="content-detail__container">

            <ContractListHeader />

            {(loading || loadingTramits || loadingTypeTramits || loading1 || loading2) && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textSpinner}
                    opacity={true}
                />
            )}

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            {renderList()}
        </div>
    );
};

export default ManageProcedures;
