import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Box } from "@mui/system";

import ContractListHeader from "../ContractListHeader";
import AddProvider from "./molecules/AddProvider";
import { CustomToobarTableList, GeneralListProced, Spinner } from "../..";
import { setearFecha, to, verifiVariable } from "../../../utils/enums";
import {
    clearContractsDetail,
    getAllAnfTramit,
} from "../../../store/formANFTramit";

const DocAnfManagement = () => {
    const {
        session: {
            login: { user },
        },
        formANFTramit: { allAnfTramit, loading },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [openForm, setOpenForm] = useState(false);
    const [dataGrid, setDataGrid] = useState([]);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        const { userId, token } = user;

        if (![null, undefined].includes(userId)) {
            dispatch(getAllAnfTramit(token));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        dispatch(clearContractsDetail());
        setDataGrid([...allAnfTramit].filter((item, index) => !verifiVariable(item["numberID"])));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allAnfTramit]);

    const updateData = () => {
        const { userId, token } = user;

        setSpinner(true);

        if (![null, undefined].includes(userId)) {
            dispatch(getAllAnfTramit(token));
        }

        setTimeout(() => {
            setSpinner(false);
        }, 5000);
    };

    const renderList = useCallback(() => {
        return (
            <Fragment>
                <br />

                <CustomToobarTableList
                    dataGrid={[...dataGrid].map((item) => {
                        return {
                            sProcess: "ANF",
                            sContractID: item["contractID"],
                            autoId: item["numberID"],
                            sContractName: item["contractName"],
                            sTipoContrato: item["typeContract"],
                            sTipoFirma: "ANF",
                            sStatus: item["status"],
                            sPaymentStatus: item["paymentStatus"],
                            dContractDT: setearFecha(item["createdAt"]),
                            withDocument: null,
                            iSignedCount: item["signedCount"],
                        };
                    })}
                    title={"Trámites ANF"}
                    subTitle={
                        "Aquí podrás visualizar todos los trámites que tienes creados"
                    }
                    onUpdate={() => updateData()}
                    onCreate={() => navigate(to.ANF_CREATE)}
                />

                <div
                    className="container--relative contracts-status-list__container"
                    style={{ height: "auto", width: "100%" }}
                >
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <GeneralListProced
                                updateList={() => updateData()}
                                dataGrid={[...dataGrid].map((item) => {
                                    return {
                                        sProcess: "ANF",
                                        sContractID: item["contractID"],
                                        autoId: item["numberID"],
                                        sContractName: item["contractName"],
                                        sTipoContrato: item["typeContract"],
                                        sTipoFirma: "ANF",
                                        sStatus: item["status"],
                                        sPaymentStatus: item["paymentStatus"],
                                        dContractDT: setearFecha(item["createdAt"]),
                                        withDocument: null,
                                        iSignedCount: item["signedCount"],
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, state]);

    return (
        <div className="content-detail__container">
            <AddProvider
                openForm={openForm}
                closeForm={(e) => setOpenForm(e)}
            />
            <ContractListHeader />

            {spinner || loading ? (
                <Box
                    sx={{ height: "150px" }}
                    className="container--relative contracts-status-list__container"
                >
                    <Spinner loading={true} type="bar" opacity={true} />
                </Box>
            ) : (
                renderList()
            )}
        </div>
    );
};

export default DocAnfManagement;
